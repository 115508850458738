.calendar {
  background-color: white;
  min-height: auto;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 2rem 1rem;
  padding: 0.5rem;
}

.header {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  align-items: center;
  justify-items: center;
  grid-template-areas: 'pagination pagination pagination' 'filter filter filter' '. add printer';
  gap: 1rem;
  padding: 1rem 0;
}

.printer {
  border: none;
  background: none;
  display: inline;
  grid-area: printer;
}

.pagination {
  grid-area: pagination;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap: 1rem;
  align-items: center;
}

.add {
  grid-area: add;
}

.select {
  display: inline;
}

.btn-pag {
  font-size: 1.1rem;
}

.filter {
  grid-area: filter;
}

.table {
  min-height: 70vh;
  overflow-x: auto;
}

.first-coloumn {
  width: 2%;
}

.thead {
  position: sticky;
  background-color: white;
  top: -1px;
  z-index: 0;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.static-table {
  width: 14%;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media print {
  .static-table {
    font-weight: bold;
  }
  .table th,
  .table td {
    padding: 0.1rem 0.2rem;
  }
}

.mobile {
  display: none;
  text-align: center;
}

@media only screen and (min-width: 10rem) {
  .mobile {
    display: block;
    margin: 0 auto;
  }
  .desktop {
    display: none;
  }
  .table th,
  .table td {
    border: 1px solid #dee2e6;
  }
}

@media (min-width: 0rem) and (max-width: 20rem) {
  .table th,
  .table td {
    border: 1px solid black;
  }
}

@media only screen and (min-width: 15rem) and (max-width: 80rem) {
  .table th,
  .table td {
    padding: 0.5rem 0.1rem;
  }

  .month {
    font-size: 1.7rem;
    text-align: left;
    margin: 0.5rem;
  }
}

@media only screen and (min-width: 75rem) {
  .calendar {
    margin: 2rem;
  }

  .header {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content min-content;
    grid-template-areas: 'filter pagination add' 'filter . printer';
  }

  .printer {
    margin: 1rem 1rem 0 0;
    justify-self: right;
  }

  .add {
    justify-self: right;
    margin-right: 1rem;
  }

  .filter {
    justify-self: left;
    margin-left: 1rem;
    align-self: flex-start;
  }
}

@media only screen and (min-width: 80rem) {
  .mobile {
    display: none;
  }

  .desktop {
    display: unset;
  }
}
