.grid {
  display: grid;
  min-height: 100vh;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: 'header' 'main' 'footer';
}

.side-menu-grid {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar';
}

.clean-menu-grid {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas: 'header' 'main';
}

.sidebar {
  grid-area: sidebar;
}

.header {
  grid-area: header;
}

.main {
  grid-area: main;
  justify-self: center;
  width: 100%;
  max-width: 120rem;
}

.footer {
  grid-area: footer;
  font-size: 0.7rem;
  color: white;
}

@media only screen and (max-width: 50rem) {
  .side-menu-grid .header,
  .side-menu-grid .main {
    display: none;
  }
}

@media only screen and (min-width: 50rem) {
  .side-menu-grid {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 3rem auto;
    grid-template-areas: 'sidebar header' 'sidebar main';
  }
}
