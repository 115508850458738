.grid {
  display: grid;
}

@media only screen and (min-width: 26.8rem) {
  .grid {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .title {
    text-align: center;
    align-self: center;
  }
}

@media only screen and (min-width: 31.25rem) {
  .card {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 50rem;
    margin: 3rem auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .large {
    max-width: 80rem;
  }
}
