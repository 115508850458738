.button {
  width: 100%;
  margin-top: 1.5rem;
}

.color {
  margin-bottom: 1rem;
  grid-area: color;
}

.client {
  margin-bottom: 1rem;
  grid-area: client;
}

@media only screen and (min-width: 31.25rem) {
  .form {
    margin: 2rem auto 2.5rem;
    display: grid;
    grid-template-columns: 8fr 1fr;
    grid-template-rows: 1fr 2.2fr;
    gap: 0rem;
    grid-template-areas: 'client color' 'contact contact' 'button button';
  }
  .button {
    grid-area: button;
    width: min-content;
  }

  .client {
    margin-right: 2rem;
  }
  .contact {
    grid-area: contact;
    border: 1px solid gainsboro;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
  }
}
