.border {
  border: 1px solid gainsboro;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.footer th,
.footer td {
  border: none;
}

.dates {
  color: #5562ae;
}

.col {
  text-align: right;
}

.rate,
.time {
  display: none;
}

@media only screen and (min-width: 30rem) {
  .time {
    display: table-cell;
  }
}

@media only screen and (min-width: 40rem) {
  .rate {
    display: table-cell;
  }
}

@media only screen and (min-width: 50rem) {
  .col {
    width: 15%;
  }
}
