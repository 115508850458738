.sidebar-link {
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1.1rem;
}
.sidebar-link:hover {
  background: #252831;
  border-left: 4px solid #632ce4;
  color: #8f68e8;
  cursor: pointer;
}

.sidebar-label {
  margin-left: 1rem;
}

.dropdown-link {
  background: #414757;
  height: 60px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 1.1rem;
}

.dropdown-link:hover {
  background: #632ce4;
  color: #f5f5f5;
  cursor: pointer;
}
