.table-head th {
  text-align: center;
  padding: 1rem;
  border-bottom: 2px solid black;
}

.row td {
  padding: 0.5rem;
}

.company {
  font-weight: bold;
  letter-spacing: 0.05rem;
}

.parent {
  margin-top: 2rem;
}

.card {
  background-color: white;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.edit {
  display: none;
}

.edit a {
  display: block;
  width: min-content;
  text-align: center;
  margin: auto;
}

.printer {
  border: none;
  background: none;
  display: block;
  margin-left: auto;
  margin-bottom: 0.5rem;
}

.checked {
  text-align: center;
  color: green;
}

@media only screen and (min-width: 1rem) {
  .edit {
    display: table-cell;
  }
  .sm .edit {
    display: none;
  }
  .md .edit {
    display: none;
  }
}

@media only screen and (max-width: 28.75rem) {
  .sm {
    display: none;
  }
}

@media only screen and (max-width: 70rem) {
  .md {
    display: none;
  }
}
