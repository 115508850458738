.item {
  padding: 0.2rem;
  height: auto;
  transition: 0.1s ease-in-out;
}

.item:hover {
  background-color: gainsboro;
}

@media print {
  .blank {
    display: none;
  }
  .heading {
    font-weight: bold;
  }
  .job-insert {
    font-size: 0.5rem;
    line-height: 0.6rem;
    margin: 0.1rem 0rem;
    padding: 0rem;
  }
  .item {
    padding: 0;
    margin: 0;
  }
}

.blank {
  margin: 1rem;
}

.job-insert {
  font-weight: 700;
  color: white;
  z-index: 100;
  padding: 0.5rem;
  height: min-content;
  border-radius: 0.2rem;
  cursor: pointer;
  margin: 0.2rem 0.1rem;
}

.job-insert:hover {
  transform: scale(1.02);
  transition: all 150ms ease-in-out;
}

.mobile {
  display: none;
}

@media only screen and (min-width: 10rem) {
  .mobile {
    display: unset;
  }
  .desktop {
    display: none;
  }
  .heading {
    position: sticky;
    left: 0;
    background-color: white !important;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 0;
  }
}

@media only screen and (max-width: 75rem) {
  .item {
    padding: 0;
  }
  .job-insert {
    padding: 0.2rem;
    margin: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .heading {
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7rem;
  }
  .job-insert {
    font-size: 0.7rem;
    color: white;
    padding: 0.4rem 0.1rem;
    max-height: auto;
    overflow-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.2rem;
    cursor: pointer;

    margin: 0.2rem 0.1rem;
  }
}

@media only screen and (min-width: 80rem) {
  .job-insert {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .mobile {
    display: none;
  }

  .desktop {
    display: unset;
  }
}
