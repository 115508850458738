.page {
  background-color: whitesmoke;
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pagination {
  display: grid;
  align-items: center;
  margin: 2rem auto;
  max-width: 16rem;
}
.btn-pag {
  display: none;
  font-size: 1.1rem;
}

@media only screen and (min-width: 30rem) {
  .pagination {
    grid-template-columns: 1fr 4fr 1fr;
    gap: 1rem;
    align-items: center;
    margin: 2rem auto;
    max-width: 16rem;
  }

  .btn-pag {
    display: unset;
  }
}
