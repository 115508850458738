.button {
  width: 100%;
  margin-top: 1.5rem;
}

.delete {
  width: 100%;
  margin-top: 1.5rem;
}

.form {
  margin: 1rem auto;
  display: grid;
  grid-template-rows: min-content;
  gap: 0.7rem;
}

@media only screen and (min-width: 31.25rem) {
  .form {
    margin: 2rem auto 2.5rem;
    grid-template-columns: 2.5fr 1fr 1fr 0.5fr;
    grid-template-rows: repeat(4, min-content);
    grid-template-areas: 'address city city city' 'client customer customer customer' 'job color area invoiced' 'button delete delete delete';
    gap: 1rem;
  }

  .address {
    grid-area: address;
  }

  .city {
    grid-area: city;
  }

  .client {
    grid-area: client;
  }

  .customer {
    grid-area: customer;
  }

  .job {
    grid-area: job;
  }

  .color {
    grid-area: color;
  }

  .area {
    grid-area: area;
  }

  .button {
    grid-area: button;
    width: min-content;
  }

  .invoiced {
    grid-area: invoiced;
    align-self: center;
  }

  .delete {
    grid-area: delete;
    width: min-content;
    justify-self: right;
  }
}

.schedule {
  margin: 3rem 0;
}

@media only screen and (min-width: 47.94rem) {
  .schedule {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
