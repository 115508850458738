.title {
  font-size: 2rem;
}

.form {
  display: grid;
  gap: 1.2rem;
}

@media only screen and (min-width: 40rem) {
  .save {
    width: max-content;
  }
}
