.page {
  background-color: whitesmoke;
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pagination {
  display: grid;
  align-items: center;
  max-width: 10rem;
}
.btn-pag {
  display: none;
  font-size: 1.1rem;
}

.card {
  border: 1px solid gainsboro;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.printer {
  border: none;
  background: none;
  display: inline;
  grid-area: printer;
}

.controls {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-items: center;
  margin: 1rem 0;
}

.printer-container {
  justify-self: right;
}

@media only screen and (min-width: 30rem) {
  .pagination {
    grid-template-columns: 1fr 4fr 1fr;
    gap: 1rem;
    align-items: center;
    max-width: 16rem;
  }

  .btn-pag {
    display: unset;
  }
}

@media only screen and (min-width: 55rem) {
  .controls {
    grid-template-columns: repeat(3, 1fr);
    justify-items: left;
    margin-bottom: 2rem;
  }

  .pagination {
    justify-self: center;
  }

  .printer-container {
    align-self: end;
  }
}
