.pagination button {
  margin: 0 0.2rem;
}

.sm {
  display: none;
}

.md {
  display: none;
}

.lg {
  display: none;
}

@media only screen and (min-width: 55rem) {
  .sm {
    display: unset;
  }
}

@media only screen and (min-width: 62.5rem) {
  .md {
    display: unset;
  }

  .lg {
    display: unset;
  }
}
