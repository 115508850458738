.button {
  width: 100%;
  margin-top: 1rem;
}

.part,
.description {
  margin: 0.5rem 0;
}

@media only screen and (min-width: 31.25rem) {
  .button {
    width: min-content;
  }
}
