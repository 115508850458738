.btn-btm-save {
  width: 100%;
}

.users {
  margin-bottom: 1rem;
}

.grid-card-top {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.header {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: baseline;
  align-content: stretch;
}

.block {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.title {
  flex-grow: 1;
  align-self: auto;
  font-size: 1.2rem;
  text-align: center;
}

.controls {
  display: none;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.btn-sml-screen-btm {
  display: block;
  margin: 0 auto;
}

.dropdown {
  width: 100%;
}

.table-coloumn-delete {
  max-width: 100%;
}

@media only screen and (min-width: 50rem) {
  .grid-card-top {
    grid-template-columns: 1fr 1fr;
  }
  .heading-h2 {
    font-size: 2rem;
  }
  .controls {
    display: block;
  }
  .btn-sml-screen-btm {
    display: none;
  }
  .dropdown {
    width: max-content;
  }
  .btn-time-save {
    justify-self: right;
  }

  .users {
    max-width: 17.1rem;
  }

  .btn-btm-save,
  .btn-time-save {
    width: min-content;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media only screen and (max-width: 50rem) {
  .timesheet-grid-container {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 0.5rem;
  }

  .timesheet-grid {
    display: grid;
    grid-template-rows: repeat(4, auto);
    border-width: 0;
  }
}

@media only screen and (min-width: 61.9rem) {
  .table-coloumn-delete {
    width: 2rem;
  }
}
