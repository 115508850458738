.desktop {
  display: none;
}

@media only screen and (min-width: 50rem) {
  .mobile {
    display: none;
  }
  .desktop {
    display: unset;
  }
}
