.parent {
  margin-top: 2rem;
}

.card {
  background-color: white;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.data {
  color: rgb(36, 36, 36);
}

.btn {
  width: min-content;
}

.responsive {
  display: none;
}

@media only screen and (min-width: 40rem) {
  .responsive {
    display: table-cell;
  }
}
