.grid {
  margin: 2rem 0rem;
  display: grid;
  gap: 2rem;
}

@media only screen and (max-width: 35.875rem) {
  .grid {
    margin: 2rem 1rem;
  }
}

@media only screen and (min-width: 50rem) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
